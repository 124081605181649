.App {
    max-width: 1000px;
    margin: auto;
    padding: 0px 10px;
}

.Header {
    display: flex;
    align-items: baseline;
    width: 95%;
    flex-wrap: wrap;
    line-height: 0em;
}

.Logo {
    -webkit-margin-after: 0px;
    -webkit-margin-before: 0px;
    font-size: 48px;
    font-weight: bold;
    margin-right: 20px;
}

.Logo a {
    color: #000;
    text-decoration: none;
}

.HeaderLinks {
    display: flex;
    margin-top: 20px;
}

.VoxelContents {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
}

.VoxelContents .Voxel:nth-child(1) {
    grid-column: 1/3;
    grid-row: 1/3;
}

.VoxelContents .Voxel:nth-child(2) {
    grid-column: 3;
    grid-row: 1/3;
}

@media (max-width: 630px) {
    .VoxelContents {
        display: grid;
        grid-template-columns: 100%;
        gap: 10px;
        margin-bottom: 20px;
    }
    
    .VoxelContents .Voxel:nth-child(1) {
        grid-column: initial;
        grid-row: initial;
    }
    
    .VoxelContents .Voxel:nth-child(2) {
        grid-column: initial;
        grid-row: initial;
    }
}

.VoxelDetail {
    max-width: 500px;
    margin: 20px auto;
}

.Footer {
    text-align: center;
    margin: 20px;
}